import { Controller } from "@hotwired/stimulus"

export default class NavbarController extends Controller {
  static targets = [
    "chevron",
    "whatWeTreatDropdown",
    "overlay",
    "menu",
    "showMenu",
    "showMenuIcon",
  ]

  toggleDropdown() {
    this.chevronTargets.forEach((chevron) => {
      chevron.classList.toggle("rotate-90")
      chevron.classList.toggle("rotate-270")
    })
    this.whatWeTreatDropdownTargets.forEach((dropdown) => {
      dropdown.classList.toggle("hidden")
    })
    this.overlayTarget.classList.toggle("hidden")
  }

  toggleMenu() {
    this.menuTarget.classList.toggle("-translate-y-out-of-screen")
    this.showMenuTarget.classList.toggle("bg-blue-20")
    this.showMenuIconTarget.classList.toggle("stroke-white")
    this.showMenuIconTarget.classList.toggle("stroke-black")
    this.whatWeTreatDropdownTargets.forEach((button) =>
      button.classList.add("hidden"),
    )
    this.overlayTarget.classList.add("hidden")
    this.chevronTargets.forEach((chevron) =>
      chevron.classList.add("rotate-90"),
    )
    this.chevronTargets.forEach((chevron) =>
      chevron.classList.remove("rotate-270"),
    )
  }
}
